import React from "react";
import "./TeamsTopSection.css";
import { useAppSelector } from "../../../hooks";

function TeamsTopSection() {
  const { team } = useAppSelector((state) => state.teams);
  return (
    <div className="app--top-container">
      {/* Team count Box Container */}
      <div className="partners--top--box-container">
        {/* Box Title Wrapper */}
        <div className="box-title-wrapper">TEAM MEMBERS COUNT</div>
        {/* Box Value Wrapper */}
        <div className="box-value-wrapper">{team?.length || 0}</div>
      </div>
    </div>
  );
}

export default TeamsTopSection;
