import React from "react";
import "./TeamsTable.css";
import { Table } from "react-bootstrap";
import moment from "moment";
import pinIcon from "../../../images/pin-icon.svg";
import { ITeamMember } from "../../../../Types";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";

interface IProps {
  isLoading: boolean;
  currentTeamMembersPosts: ITeamMember[] | null;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setSelectedMemberForPreview: React.Dispatch<
    React.SetStateAction<ITeamMember | null>
  >;
}

const TeamsTable = function ({
  isLoading,
  currentTeamMembersPosts,
  setPageContent,
  setSelectedMemberForPreview,
}: IProps) {
  return (
    <>
      {/* Employers Table Container */}
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {["NAME", "EMAIL", "ROLE", "DATE ADDED"].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentTeamMembersPosts?.map((partner, i) => (
              <tr
                key={i + 1}
                onClick={() => {
                  //  Set Selected member and change page to preview
                  setSelectedMemberForPreview(partner);
                  // setPageContent("preview");
                }}
              >
                {/* Name Column */}
                <td className="td-primary">
                  <div className="td-employee-name--wrapper">
                    {partner.firstName} {partner.lastName}
                  </div>
                </td>

                {/*  Email Column */}
                <td className="td-primary">
                  <div className="td-email---wrapper">{partner.email}</div>
                </td>

                {/* Role Column */}
                <td className="td-primary">
                  <div className="td-role--wrapper">{partner.role}</div>
                </td>

                {/*  Date Added Column */}
                <td className="td-date-added">
                  <div className="td-date-added--wrapper">
                    {moment(partner.createdAt).format("Do MMM, YYYY")}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </>
  );
};

export default TeamsTable;
