import { useContext } from "react";
import { useAppDispatch } from "../../../hooks";
import useInactivityLogout from "../../../hooks/useInactivityLogout";
import { logoutUser } from "../../../redux/actions/authActions";
import AccountSetup from "../../account-setup/AccountSetup";
import { UserContext } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";

function DashboardSetup() {
  // Funtion, State and Variables
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setUser }: any = useContext(UserContext);
  // Functions
  // Handles logout of user
  const handleLogOutUser = function () {
    dispatch(logoutUser(navigate, setUser));
  };

  useInactivityLogout({
    inactivityExpireTime: Number(process.env.REACT_APP_INACTIVITY_EXPIRE_TIME),
    onExpired: handleLogOutUser,
  });

  return <AccountSetup pageType={"dash-setup"} />;
}

export default DashboardSetup;
