import { MouseEvent, useState } from "react";
import "./OnboardTeam.css";
import { Link, useParams } from "react-router-dom";
import eyeOpenIcon from "../../../images/eye-open-icon.svg";
import eyeCloseIcon from "../../../images/eye-close-icon.svg";
import AuthNavbar from "../../../components/auth-navbar/AuthNavbar";
import authWaveImage from "../../../images/signin-wave-image.svg";
import { onboardTeam } from "../../../redux/actions/teamActions";
import useDisplayMessage from "../../../hooks/useDisplayMessage";
import { useAppDispatch } from "../../../hooks";
import RegisterSuccessful from "../../../components/register-successful-comp/RegisterSuccessful";
import ErrorHandler from "../../../components/error/ErrorHandler";
import TextInput from "../../../components/input-components/text-input/TextInput";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";

function OnboardTeam() {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const { partnerId } = useParams();
  // States
  const [teamFormInputStates, setTeamFormInputStates] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    isTermsAndConditionsChecked: false,
  });
  //
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const [isPasswordMatching, setIsPasswordMatching] = useState(true);
  const [registerSuccessful, setRegisterSuccessful] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  // Hooks
  const { errorHandlerObj, setErrorHandlerObj } = useDisplayMessage();

  //   Functions
  const handleUpdateFormInputState = function (
    fieldName: string,
    fieldValue: string | boolean
  ) {
    const updatedFormInputStateObject = {
      ...teamFormInputStates,
      [fieldName]: fieldValue,
    };
    setTeamFormInputStates(updatedFormInputStateObject);
  };

  // handle submit onboarding  form
  const handleSubmitOnboardingDetails = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    setIsPasswordMatching(true);
    setErrorHandlerObj({ hasError: false, message: "" });

    // Is form empty
    const isFormEmpty = Object.keys(teamFormInputStates).some(
      (inputKey) =>
        teamFormInputStates[inputKey as keyof typeof teamFormInputStates] === ""
    );
    const { password, confirmPassword } = teamFormInputStates;
    if (!isFormEmpty && teamFormInputStates.isTermsAndConditionsChecked) {
      e.preventDefault();
      if (password !== confirmPassword) {
        e.preventDefault();
        setIsPasswordMatching(false);
      } else {
        const data = {
          firstName: teamFormInputStates.firstName,
          lastName: teamFormInputStates.lastName,
          email: teamFormInputStates.email,
          partnerId: partnerId || "",
          password: teamFormInputStates.password,
        };
        dispatch(
          onboardTeam(
            data,
            setIsLoading,
            setErrorHandlerObj,
            setRegisterSuccessful
          )
        );
      }
      setTimeout(() => {
        setIsPasswordMatching(true);
      }, 10000);
    }
  };

  return (
    <div className="employer-signup-container">
      <AuthNavbar />
      {/* Successful Register Form Submission Container */}
      <div
        className={`successful-form-submission-container ${
          !registerSuccessful && "none"
        }`}
      >
        <RegisterSuccessful
          displayText={
            "You have successfully onboarded your account. Kindly go on to log in."
          }
        />
      </div>

      <div
        className={`employer-signup-container--inner ${
          registerSuccessful && "none"
        }`}
      >
        {/* Employer OnboardTeam Left section  / Auth Left Section*/}
        <section className="auth-left-section employer-signup-left-section">
          {/* Employer OnboardTeam left section text section */}
          <div className="employer-signup-left-section-text-wrapper">
            Improve the quality of life of your employees
          </div>

          {/* Auth Left Image Wrapper */}
          <div className="left-auth-section-image-wrapper">
            <img src={authWaveImage} alt="" />
          </div>
        </section>

        {/* Employer OnboardTeam Right section  / Auth Right Section*/}
        <section className="auth-right-section employer-signup-right-section">
          <div className="auth-message-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
          </div>
          <div className="employer-signup-right-section--inner">
            {/* Employer OnboardTeam right section header */}
            <div className="employer-signup-right-section-header">
              {/* Employer OnboardTeam right section header title */}
              <div className="employer-signup-right-section-header-title">
                Partner Team Onboarding
              </div>

              <div className="employer-signup-right-section-header-sub-title">
                Already have an account,{" "}
                <span>
                  <Link to={"/signin"}>Sign in</Link>
                </span>
              </div>
            </div>

            {/* Employer OnboardTeam main form section */}
            <div className="employer-signup-main-form-section">
              <form>
                {/* First Name form group */}
                <div className="form-group">
                  <TextInput
                    type={"text"}
                    id={"team-firstname-text-input"}
                    className={"team-firstname-input"}
                    placeholder={""}
                    value={teamFormInputStates.firstName}
                    onChange={(e) =>
                      handleUpdateFormInputState("firstName", e.target.value)
                    }
                    required={true}
                    label={"First Name"}
                  />
                </div>

                {/* Last Name form group */}
                <div className="form-group">
                  <TextInput
                    type={"text"}
                    id={"team-lastname-text-input"}
                    className={"team-lastname-input"}
                    placeholder={""}
                    value={teamFormInputStates.lastName}
                    onChange={(e) =>
                      handleUpdateFormInputState("lastName", e.target.value)
                    }
                    required={true}
                    label={"Last Name"}
                  />
                </div>

                {/*  Email form group */}
                <div className="form-group">
                  <TextInput
                    type={"email"}
                    id={"team-email-text-input"}
                    className={"team-email-input"}
                    placeholder={""}
                    value={teamFormInputStates.email}
                    onChange={(e) =>
                      handleUpdateFormInputState("email", e.target.value)
                    }
                    required={true}
                    label={"Email"}
                    subLabel={"Your Invite Mail"}
                  />
                </div>

                {/* Password form group */}
                <div className="employer-signup-password-form-group form-group">
                  <img
                    src={isPasswordVisible ? eyeOpenIcon : eyeCloseIcon}
                    alt=""
                    className="password-icon-wrapper"
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  />

                  <label htmlFor={"employer-signup-password-input"}>
                    Password
                    <span>(Min 1 upper-cased, 1 number and 1 symbol)</span>
                  </label>
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    id={"employer-signup-password-input"}
                    className={"employer-signup-password-text-input"}
                    value={teamFormInputStates.password}
                    onChange={(e) =>
                      handleUpdateFormInputState("password", e.target.value)
                    }
                    required={true}
                    placeholder="At least 8 characters"
                  />
                </div>

                {/* Password form group */}
                <div className="employer-signup-confirm-password-form-group form-group">
                  <img
                    src={isConfirmPasswordVisible ? eyeOpenIcon : eyeCloseIcon}
                    alt=""
                    className="password-icon-wrapper"
                    onClick={() =>
                      setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
                    }
                  />

                  <label htmlFor={"employer-signup-confirm-password-input"}>
                    Retype Password
                  </label>
                  <input
                    type={isConfirmPasswordVisible ? "text" : "password"}
                    id={"employer-signup-confirm-password-input"}
                    className={"employer-signup-confirm-password-text-input"}
                    value={teamFormInputStates.confirmPassword}
                    onChange={(e) =>
                      handleUpdateFormInputState(
                        "confirmPassword",
                        e.target.value
                      )
                    }
                    required={true}
                  />
                </div>

                {/* Password not matching error message */}
                <div
                  className={`password-not-matching-error-message-wrapper ${
                    !isPasswordMatching ? "" : "none"
                  }`}
                >
                  Passwords do not match!
                </div>

                {/* Terms and Conditions Form Group */}
                <div className="terms-conditions-form-group">
                  <input
                    type="checkbox"
                    name="terms-conditions-checkbox"
                    checked={teamFormInputStates.isTermsAndConditionsChecked}
                    onChange={(e) =>
                      handleUpdateFormInputState(
                        "isTermsAndConditionsChecked",
                        !teamFormInputStates.isTermsAndConditionsChecked
                      )
                    }
                    id="terms-and-conditions-checkbox"
                    required
                  />
                  <label htmlFor="terms-and-conditions-checkbox">
                    I agree to the{" "}
                    <Link to="/partner-mou" target="_blank">
                      Terms and Conditions
                    </Link>{" "}
                    and{" "}
                    <a
                      href={`${process.env.REACT_APP_LANDING_URL}/privacy`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </label>
                </div>

                {/* Submit form button wrapper */}
                <div className="employer-signup-button-wrapper">
                  {isLoading && <LoadingSpinner />}
                  <button onClick={handleSubmitOnboardingDetails}>
                    Submit
                  </button>
                </div>

                {/* Not an employer wrapper wrapper  */}
                {/* <div className="not-an-employer-wrapper">
                  <div className="not-an-employer-wrapper--inner">
                    <div className="row-one">
                      Not an Employer,
                      <span>
                        <a
                          href={`${process.env.REACT_APP_MERCHANT_URL}/merchant/signup`}
                        >
                          Sign up as Merchant
                        </a>
                      </span>
                    </div>
                  </div>
                </div> */}
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default OnboardTeam;
