export const partnerRolesOptions = [
  { key: "Admin", value: "Admin" },
  { key: "Developer", value: "Developer" },
  { key: "Member", value: "Member" },
];

export const partnerRolesAccessArr = [
  {
    roleKey: "Admin",
    access: [
      "Dashboard",
      "Documentation",
      "Transactions",
      "API Details",
      "Teams",
    ],
  },
  {
    roleKey: "Developer",
    access: [
      "Dashboard",
      "Documentation",
      "Transactions",
      "API Details",
      // "Teams",
    ],
  },
  {
    roleKey: "Member",
    access: [
      "Dashboard",
      "Documentation",
      "Transactions",
      "API Details",
      // "Teams",
    ],
  },
];

export const handleGetRoleNameFromKey = function (roleKey: string) {
  const currentRoleObject = partnerRolesOptions.find(
    (role) => role.key === roleKey
  );
  if (currentRoleObject) return currentRoleObject.value;
  return "";
};

export const handleGetPartnerRoleAccessFromRoleKey = function (
  roleKey: string
) {
  const currentRoleObject = partnerRolesAccessArr.find(
    (role) => role.roleKey === roleKey
  );
  if (currentRoleObject) return currentRoleObject.access;
  return [];
};
