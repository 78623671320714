import "./FileUploadInput.css";
import uploadIcon from "../../../images/upload-icon.svg";

interface IProps {
  label: string;
  value: string;
  name: string;
  isLoading: boolean;
  placeholder: string;
  required: boolean;
  onChange: (e: any) => void;
}

function FileUploadInput({
  label,
  value,
  name,
  isLoading,
  placeholder,
  required,
  onChange,
}: IProps) {
  return (
    <div className="shop-details-setup-cac-doc-form-group file-form-group">
      <label htmlFor={`${name}_upload-doc-file-input`}>
        {label}
        {!required && <span>Optional *</span>}
      </label>
      <div className="file-form-group--inner">
        <div className="file-input-wrapper">
          <div className="file-input-title">
            {value ? `Change ${placeholder}` : `Upload ${placeholder}`}
          </div>
          {isLoading && <div className="upload-loading-spinner-wrapper"></div>}
          <input
            type="file"
            id={`${name}_upload-doc-file-input`}
            className={"upload-doc-input"}
            placeholder={""}
            onChange={onChange}
            required={required}
          />
        </div>
        {value && (
          <div className="preview-cac-document-icon">
            <a href={`${value}`} target={"_blank"} rel="noreferrer">
              <img src={uploadIcon} alt="" />
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default FileUploadInput;
