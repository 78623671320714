export const GET_ERRORS = "GET_ERRORS";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
// API Configs
export const GET_API_DETAILS = "GET_API_DETAILS";
// Transactions
export const GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS";
export const GET_ALL_CREDIT_TRANSACTIONS = "GET_ALL_CREDIT_TRANSACTIONS";
export const GET_ALL_DEBIT_TRANSACTIONS = "GET_ALL_DEBIT_TRANSACTIONS";
// TEAMS
export const GET_ALL_TEAM_MEMBER = "GET_ALL_TEAM_MEMBER";
