import React from "react";
import "./PrimaryButton.css";

// Interfaces
interface IProps {
  className?: string;
  placeholder: string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}
function PrimaryButton({ className, placeholder, onClick }: IProps) {
  return (
    <div className={`primary-button-wrapper ${className}`}>
      <button onClick={onClick}>{placeholder}</button>
    </div>
  );
}

export default PrimaryButton;
