import React, { useState, useEffect, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import AuthNavbar from "../../components/auth-navbar/AuthNavbar";
import "./Signin.css";
import authWaveImage from "../../images/signin-wave-image.svg";
import TextInput from "../../components/input-components/text-input/TextInput";
import { Link } from "react-router-dom";
import eyeOpenIcon from "../../images/eye-open-icon.svg";
import eyeCloseIcon from "../../images/eye-close-icon.svg";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import ErrorHandler from "../../components/error/ErrorHandler";
import { useAppDispatch } from "../../hooks";
import { loginUser } from "../../redux/actions/authActions";

function Signin() {
  // Functions, States and Variables
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // States
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: true,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  // Functions
  const handleSubmitLoginDetails = function (e: MouseEvent<HTMLButtonElement>) {
    setErrorHandlerObj({ hasError: false, message: "" });
    if (email !== "" && password !== "") {
      e.preventDefault();

      const userData = {
        email,
        password,
      };

      dispatch(loginUser(userData, setIsLoading, setErrorHandlerObj, navigate));
    }
  };

  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      let xCoord = 0,
        yCoord = 0;

      //scroll to page top
      window.scrollTo(xCoord, yCoord);
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
    }
  }, [successHandlerObj]);
  return (
    <div className="signin-container">
      <AuthNavbar />

      {/* Signin container--inner  */}
      <div className="signin-container--inner">
        {/* Signin Left section  / Auth Left Section*/}
        <section className="auth-left-section signin-left-section">
          {/* Signin left section text section */}
          <div className="signin-left-section-text-wrapper">
            Make sales and your money is ensured
          </div>

          {/* Auth Left Image Wrapper */}
          <div className="left-auth-section-image-wrapper">
            <img src={authWaveImage} alt="" />
          </div>
        </section>

        {/* Signin Right section  / Auth Right Section*/}
        <section className="auth-right-section signin-right-section">
          <div className="signin-right-section--inner">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />

            {/* Signin right section header */}
            <div className="signin-right-section-header">
              {/* Signin right section header title */}
              <div className="signin-right-section-header-title">Log in</div>
            </div>

            {/* Signin main form section */}
            <div className="signin-main-form-section">
              <form>
                {/* Email form group */}
                <div className="signin-email-form-group form-group">
                  <TextInput
                    type={"email"}
                    id={"signin-email-text-input"}
                    className={"signin-email-input"}
                    placeholder={""}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required={true}
                    label={"Email"}
                  />
                </div>

                {/* Password form group */}
                <div className="signin-password-form-group form-group">
                  <img
                    src={isPasswordVisible ? eyeOpenIcon : eyeCloseIcon}
                    alt=""
                    className="password-icon-wrapper"
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  />

                  <label htmlFor={"signin-password-input"}>Password</label>
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    id={"signin-password-input"}
                    className={"signin-password-text-input password-input"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required={true}
                  />
                </div>

                <div className="forgot-password-link-wrapper">
                  <Link to={"/forgot-password"}>Forgot password?</Link>
                </div>

                {/* Submit form button wrapper */}
                <div className="log-in-button-wrapper">
                  {isLoading && <LoadingSpinner />}
                  <button onClick={handleSubmitLoginDetails}>Log in</button>
                </div>

                {/* Don't have an account wrapper  */}
                <div className="dont-have-an-account-wrapper">
                  <div className="row-one">
                    Don't have an account, you can
                    <span>
                      <Link to="/partner/signup">Sign up</Link>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Signin;
