import { useEffect, useState } from "react";
import "./DashboardAwaiting.css";
import whiteStarIcon from "../../../images/white-star.svg";
import arrowRight from "../../../images/arrow-long-right.svg";
import aboutOurTeamIcon from "../../../images/about-our-team.jpg";
import Top from "../../app-top/Top";
import ErrorHandler from "../../error/ErrorHandler";
import { useNavigate } from "react-router-dom";

function DashboardAwaiting() {
  // Functions, States and Variables
  const navigate = useNavigate();
  // States
  // Functions, States and Variables
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });

  // Functions
  const handleNavigateToDashboard = function () {
    navigate("/partner/dashboard");
  };

  // UseEffects
  useEffect(() => {
    if (errorHandlerObj.hasError)
      window.scrollTo({ top: 0, behavior: "smooth" });
  }, [errorHandlerObj]);

  return (
    <div className="dashboard-setup-container">
      <Top />
      {/* <Navbar /> */}
      {/* <Navbar /> */}

      <div className="verification-message-wrapper">
        {/* Error handler component */}
        <ErrorHandler errorHandlerObj={errorHandlerObj} />
      </div>

      <section className="dashboard-awaiting-data-section">
        {/* Container Header  */}
        <div className="dashboard-awaiting-data-section-header">
          <div className="dashboard-awaiting-data-section-header-hero-img-wrapper">
            <img src={aboutOurTeamIcon} alt="" />
          </div>

          <div className="dashboard-awaiting-data-section-header-title">
            We know you are ready to supercharge your team with this Goody
          </div>

          <div className="dashboard-awaiting-data-section-header-sub-title">
            We are reviewing your data 🚀
          </div>
        </div>

        {/* Dashboard Setup Onboarding Section Main Container */}
        <div className="dashboard-awaiting-data-section-main-container">
          {/* Go to Wrapper Container */}
          <div className="go-to-setup-links-wrapper-container">
            {/* Stock setup link wrapper */}
            <div className="go-to-setup-link-wrapper go-to-stock-setup-wrapper">
              <button onClick={() => handleNavigateToDashboard()}>
                View Your Dashboard
                {/* Arrow Image Wrapper */}
                <div className="arrow-img-wrapper">
                  <img src={arrowRight} alt="" />
                </div>
              </button>

              {/* Star Image Icon */}
              <div className="star-image-wrapper">
                <img src={whiteStarIcon} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DashboardAwaiting;
