import axios from "axios";
import { api } from "../../api/config";
import {
  SetErrorHandlerType,
  SetIsLoadingType,
  SetSuccessHandlerType,
} from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";
import { NavigateFunction } from "react-router-dom";

interface ISignup {
  userdata: {
    name: string;
    email: string;
    phoneNumber: string;
    password: string;
  };
}

interface IOnboardData {
  cacDocumentImage: string;
  utilityImage: string;
  address: string;
  natureOfBusiness: string;
}

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

const url = `${api}/api/v1/partners`;

// Register Partner
export const registerPartner =
  (
    userData: ISignup["userdata"],
    setErrorHandlerObj: SetErrorHandlerType,
    setIsLoading: SetIsLoadingType,
    setRegisterSuccessful: React.Dispatch<React.SetStateAction<boolean>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(url, userData)
      .then((res) => {
        //  Set the register success state
        setRegisterSuccessful(true);

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Onboard Partner
export const onboardPartner =
  (
    data: IOnboardData,
    pageType: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/onboard`, data)
      .then((res) => {
        if (pageType === "settings") {
          setSuccessHandlerObj({
            isSuccess: true,
            message: "Account details updated successfully!",
          });
        } else {
          //  Navigate to awaiting approval page
          navigate("/partner/dashboard/awaiting-approval");
        }
      })
      .catch((err) => {
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      })
      .finally(() => setIsLoading(false));
  };
