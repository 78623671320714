import React, { useState, MouseEvent } from "react";
import "./FormSection.css";
import TextInput from "../../../../components/input-components/text-input/TextInput";
import DropdownInput from "../../../../components/input-components/dropdown-input/DropdownInput";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import PrimaryButton from "../../../../components/buttons/primary-button/PrimaryButton";
import { SetErrorHandlerType } from "../../../../Types";
import { useAppDispatch } from "../../../../hooks";
import { useNavigate } from "react-router-dom";
import { inviteTeam } from "../../../../redux/actions/teamActions";
import { partnerRolesOptions } from "../../../../utils/partnerRolesOptions";

// Interfaces
interface IProps {
  setErrorHandlerObj: SetErrorHandlerType;
}
function FormSection({ setErrorHandlerObj }: IProps) {
  // Functions, states and variables
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // States
  const [teamFormInputStates, setTeamFormInputStates] = useState({
    email: "",
    role: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  //   Functions
  const handleUpdateFormInputState = function (
    fieldName: string,
    fieldValue: string
  ) {
    const updatedFormInputStateObject = {
      ...teamFormInputStates,
      [fieldName]: fieldValue,
    };
    setTeamFormInputStates(updatedFormInputStateObject);
  };

  //   Handle Submit Form
  const handleInviteTeamMember = function (e: MouseEvent<HTMLButtonElement>) {
    setErrorHandlerObj({ hasError: false, message: "" });
    const isFormEmpty = Object.keys(teamFormInputStates)?.some((inputKeys) => {
      return (
        teamFormInputStates[inputKeys as keyof typeof teamFormInputStates] ===
        ""
      );
    });
    if (!isFormEmpty) {
      e.preventDefault();

      const data = teamFormInputStates;
      //   TODO Call the API to invite team
      dispatch(inviteTeam(data, setIsLoading, setErrorHandlerObj, navigate));
    }
  };

  return (
    <section className="teams-form-container">
      <form>
        <div className="form-group-wrapper">
          {/* Email form group */}
          <div className="form-group">
            <TextInput
              type={"email"}
              id={"team-email-text-input"}
              className={"team-email-input"}
              value={teamFormInputStates.email}
              onChange={(e) =>
                handleUpdateFormInputState("email", e.target.value)
              }
              required={true}
              label={"Email"}
            />
          </div>

          {/* Role form group */}
          <div className="form-group">
            <DropdownInput
              id={"team-role-input"}
              className={"team-role-input"}
              label={"Role"}
              required={true}
              optionArray={[
                { key: "", value: "", default: "default" },
                ...partnerRolesOptions,
              ]}
              value={teamFormInputStates.role}
              onChange={(e) => {
                handleUpdateFormInputState("role", e.target.value);
              }}
            />
          </div>
        </div>

        {/* Submit form button */}
        <div className="teams-form-primary-btn-container primary-button-container">
          {isLoading && <LoadingSpinner />}
          <PrimaryButton
            className="teams-setup-form--action-btn"
            placeholder="Submit"
            onClick={(e) => handleInviteTeamMember(e)}
          />
        </div>
      </form>
    </section>
  );
}

export default FormSection;
