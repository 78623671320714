import React, { useContext, useEffect, useState } from "react";
import "./Dashboard.css";
import Top from "../../components/app-top/Top";
import Main from "../../components/main-container/Main";
import Sidebar from "../../components/app-sidebar/Sidebar";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import ErrorHandler from "../../components/error/ErrorHandler";
import { useAppDispatch } from "../../hooks";
import { UserContext } from "../../components/contexts/UserContext";
import { usePaystackPayment } from "react-paystack";
import SuccessHandler from "../../components/success/SuccessHandler";
import FundWalletModal from "./fund-wallet-modal/FundWalletModal";
import { fundWallet } from "../../redux/actions/transactionsActions";

function Dashboard() {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const { user: userInfo, fetchUser }: any = useContext(UserContext);
  // Functions
  // Hooks
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();
  // States
  const [fundAmount, setFundAmount] = useState("");
  const [paymentReference, setPaymentReference] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFundWalletModalOpened, setIsFundWalletModalOpened] = useState(false);

  // Paystack Configuration and Initialization
  const config = {
    reference: paymentReference,
    email: userInfo?.email || "",
    amount: Number(fundAmount) * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY ?? "",
  };

  const initializePayment: any = usePaystackPayment(config);

  // Functions
  const handleGetWalletFundingAmount = function () {
    setPaymentReference("");
    setIsFundWalletModalOpened(true);
  };

  const handleTriggerFundWallet = function () {
    // Handle Clear Messages
    setErrorHandlerObj({ hasError: false, message: "" });
    setSuccessHandlerObj({ isSuccess: false, message: "" });

    const referenceData = new Date().getTime().toString();
    setPaymentReference(referenceData);
  };

  // Handles on Sucess of payment using paystack gateway (calls the credit all employees API)
  const onPaystackSuccess = () => {
    setSuccessHandlerObj({
      isSuccess: true,
      message: "Wallet fund has been initiated!",
    });
    // Close Modal and reset payment reference to default empty
    setIsFundWalletModalOpened(false);
    setPaymentReference("");
    // Refetch user data to update wallet balance
    fetchUser();
  };

  // Handles Error or Close of Paystack gateway modal
  const onPaystackClose = function () {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    setErrorHandlerObj({ hasError: true, message: "Payment failed!" });
    setPaymentReference("");
    // Refetch user data to update wallet balance
    fetchUser();
  };

  const handleTriggerPaymentGateway = function () {
    //  Initialize payment through paystack gateway || Trigger Paystack API to completed payment
    initializePayment(onPaystackSuccess, onPaystackClose);
  };

  useEffect(() => {
    // Trigger Payment gateway after sending in the payment reference
    if (paymentReference) {
      const data = { reference: paymentReference };
      // console.log(config, data);
      //  Call API to initiate wallet funding
      dispatch(
        fundWallet(
          data,
          setIsLoading,
          setErrorHandlerObj,
          handleTriggerPaymentGateway
        )
      );
    }
  }, [paymentReference]);

  return (
    <div
      className={`app-page-container ${
        isFundWalletModalOpened ? "overflow-hidden" : ""
      }`}
    >
      <Top />
      <Sidebar activeName={"Dashboard"} />

      {/* Gray Overlay */}
      <div
        onClick={() => {
          setIsFundWalletModalOpened(false);
        }}
        className={`gray-overlay ${isFundWalletModalOpened ? "" : "none"}`}
      ></div>

      {/* Modals */}
      <FundWalletModal
        isModalOpened={isFundWalletModalOpened}
        fundAmount={fundAmount}
        isLoading={isLoading}
        setFundAmount={setFundAmount}
        setIsModalOpened={setIsFundWalletModalOpened}
        handleTriggerFundWallet={handleTriggerFundWallet}
      />

      <Main>
        <div className="app-page-container--inner">
          <div className="app--actions-handler-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
            {/* Success handler component */}
            <SuccessHandler successHandlerObj={successHandlerObj} />
          </div>

          {/* app-container-main-section */}
          <section className="app-container-main-section">
            {/* merchant-dashboard-container inner */}
            <div className="merchant-dashboard-container-wrapper">
              {/* ROW ONE SECTION */}
              <section className="merchant-container-row--one merchant-dashboard-row-section">
                {/* Wallet Balance container / Row one Left */}
                <div className="row-one--left-container wallet-balance-container">
                  {/* Top Wrapper */}
                  <div className="row-one--left--top-wrapper">
                    <div className="row-one--box-title wallet-balance-box-title">
                      WALLET BALANCE
                    </div>

                    <div className="make-withdrawal-btn-container">
                      <button onClick={() => handleGetWalletFundingAmount()}>
                        Fund Wallet
                      </button>
                    </div>
                  </div>

                  {/* Bottom Wrapper */}
                  <div className="row-one--left--bottom-wrapper">
                    {/* Wallet balancee value wrapper */}
                    <div className="wallet-balance-value-wrapper">
                      &#8358;
                      {userInfo?.partnerId?.walletBalance?.toLocaleString() ||
                        0}
                    </div>

                    {/* wallet balance percent chang wrapper */}
                    {/* <div
                      className={`row--one-percent-change-wrapper wallet-balance-percent-change-wrapper ${"percent-increase-wrapper"}`}
                    >
                      0% <img src={percentIncreaseIcon} alt="" />
                    </div> */}
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
      </Main>
    </div>
  );
}

export default Dashboard;
