import React, { MouseEvent, useEffect, useState } from "react";
import formFilledIcon from "../../../../images/form-filled-icon.svg";
import formUnfilledIcon from "../../../../images/form-unfilled-icon.svg";
import { ISetupSubPages } from "../../../../Types";
import { setupSubPages } from "../../../../utils/setupSubPages";
import { useNavigate } from "react-router-dom";

// Interfaces
interface IProps {
  pageContent: string;
  pageType: string;
  isAccountDetailsFormFilled: boolean;
  isAPISecretKeyExisting: boolean;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  handleLogOutUser: (e: MouseEvent<HTMLButtonElement>) => void;
}

function SetupNavigation({
  pageContent,
  pageType,
  isAccountDetailsFormFilled,
  isAPISecretKeyExisting,
  setPageContent,
  handleLogOutUser,
}: IProps) {
  // Functions, States and Variables
  const navigate = useNavigate();
  // States
  const [basicSetupSubPages, setBasicSetupSubPages] = useState(setupSubPages);

  //   UseEffects
  useEffect(() => {
    const currentBasicSetupSubPages = [
      {
        key: "company-info-setup",
        value: "Account Details",
        isSetupFormFilled: isAccountDetailsFormFilled,
      },
      // {
      //   key: "api-config-setup",
      //   value: "API Configuration",
      //   isSetupFormFilled: isAPISecretKeyExisting,
      // },
    ];

    setBasicSetupSubPages(currentBasicSetupSubPages);
  }, [isAccountDetailsFormFilled, isAPISecretKeyExisting]);

  return (
    <div className="dashboard-setup-layout--left-section dashboard-setup-layout-navigation-container">
      {/* Account Details Setup Nav Link */}
      {basicSetupSubPages.map((navigationObj: ISetupSubPages, index) => (
        <div key={index + 1} className="form-navigation-link">
          <button
            className={`${
              pageContent === navigationObj.key && "active-form-nav-link"
            }`}
            onClick={() => {
              if (navigationObj.key === "choose-sub-plan") {
                navigate("/partner/choose-subscription-plan");
              } else {
                setPageContent(navigationObj.key);
              }
            }}
          >
            {/* {navigationObj.key !== "choose-sub-plan" && (
            )} */}
            <img
              src={
                navigationObj.isSetupFormFilled
                  ? formFilledIcon
                  : formUnfilledIcon
              }
              alt=""
            />
            {navigationObj.value}
          </button>
        </div>
      ))}

      {/* Instruments Setup Nav Link */}
      {/* <div className="form-navigation-link instruments-setup-form-link">
      <button
        className={`${
          pageContent === "instruments-setup" && "active-form-nav-link"
        }`}
        onClick={() => setPageContent("instruments-setup")}
      >
        <img
          src={
            isInstrumentsFormFilled ? formFilledIcon : formUnfilledIcon
          }
          alt=""
        />
        Instruments
      </button>
    </div> */}

      {/* Log out btn wrapper */}
      {pageType === "settings" && (
        <div className="log-out-btn-wrapper">
          <button onClick={(e) => handleLogOutUser(e)}>Log out</button>
        </div>
      )}
    </div>
  );
}

export default SetupNavigation;
