import React, { useState, useEffect } from "react";
import "./AccountSetup.css";
import whiteStarIcon from "../../images/white-star.svg";
import arrowRight from "../../images/arrow-long-right.svg";
import smileEmoji from "../../images/smile-emoji.png";
import digitalNomadImage from "../../images/digital-nomad-working.jpg";
import SetupLayout from "./setup-layout/SetupLayout";
import Top from "../app-top/Top";
import Navbar from "../app-navbar/Navbar";
import ErrorHandler from "../error/ErrorHandler";

interface IProps {
  pageType: string;
}

const AccountSetup = function ({ pageType }: IProps) {
  // Since this comp is reusable, it takes a pageType prop specifying the page u the comp
  // Funtion, State and Variables
  // States
  const [pageContent, setPageContent] = useState("main");

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });

  // USEEFFECT
  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      //scroll to page top
      window.scrollTo({ top: 0 });
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
    }
  }, [successHandlerObj]);

  // USEEFFECT
  useEffect(() => {
    //   If page setup is settings setup, set page content to account details straight
    if (pageType !== "dash-setup") setPageContent("company-info-setup");
  }, []);

  return (
    <div className="dashboard-setup-container">
      <Top />
      <Navbar />

      <div className="acount-setup--actions-handler-wrapper">
        {/* Error handler component */}
        <ErrorHandler errorHandlerObj={errorHandlerObj} />
      </div>
      {pageType === "dash-setup" && (
        <div
          className={`dashboard-setup-container--inner ${
            pageContent !== "main" ? "none" : ""
          }`}
        >
          {/* Dashboard Setup Onboarding Section */}
          <section className="dashboard-setup-onboarding-section">
            <div className="digital_nomad_img_wrapper">
              <img src={digitalNomadImage} alt="digital-nomad-working" />
            </div>
            {/* Container Header  */}
            <div className="dashboard-setup-onboarding-section-header">
              <div className="dashboard-setup-container-onboarding-section-title">
                Your account is almost done{" "}
                <>
                  <img src={smileEmoji} alt="" />
                </>
              </div>
            </div>

            {/* Dashboard Setup Onboarding Section Main Container */}
            <div className="dashboard-setup-onboarding-section-main-container">
              {/* Go to Wrapper Container */}
              <div className="go-to-setup-links-wrapper-container">
                {/* Shop Details setup link wrapper */}
                <div className="go-to-setup-link-wrapper go-to-company-info-setup-wrapper">
                  <button onClick={() => setPageContent("company-info-setup")}>
                    Set up Onboarding Info
                    {/* Arrow Image Wrapper */}
                    <div className="arrow-img-wrapper">
                      <img src={arrowRight} alt="" />
                    </div>
                  </button>

                  {/* Star Image Icon */}
                  <div className="star-image-wrapper">
                    <img src={whiteStarIcon} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}

      {/*Dashboard Setup Section */}
      <section
        className={`dashboard-setup-form-section ${
          pageContent === "main" || pageContent === "setup-done" ? "none" : ""
        }`}
      >
        <SetupLayout
          pageContent={pageContent}
          pageType={pageType}
          errorHandlerObj={errorHandlerObj}
          setErrorHandlerObj={setErrorHandlerObj}
          setPageContent={setPageContent}
        />
      </section>
    </div>
  );
};

export default AccountSetup;
