import React, { MouseEvent, useState } from "react";
import "./FundWalletModal.css";
import SubstituteLoadingSpinner from "../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import TextInput from "../../../components/input-components/text-input/TextInput";
import { handleFormatNumberInputs } from "../../../utils/handleFormatNumberInputs";

interface IProps {
  isModalOpened: boolean;
  fundAmount: string;
  isLoading: boolean;
  setFundAmount: React.Dispatch<React.SetStateAction<string>>;
  setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  handleTriggerFundWallet: () => void;
}

function FundWalletModal({
  isModalOpened,
  fundAmount,
  isLoading,
  setFundAmount,
  setIsModalOpened,
  handleTriggerFundWallet,
}: IProps) {
  // Functions and states
  // States

  //   Functions;
  const handleConfirmTriggerPaymentGateway = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    if (fundAmount !== "") {
      e.preventDefault();
      // Call API to send reference and on success, trigger payment gateway
      handleTriggerFundWallet();
    }
  };

  return (
    <div
      className={`fund-wallet-modal-container ${isModalOpened ? "" : "none"}`}
    >
      <div className="fund-wallet-modal-container--inner">
        <div className="fund-wallet-modal-header">
          Fund Wallet {isLoading && <SubstituteLoadingSpinner />}
        </div>

        {/* Group name form wrapper */}
        <div className="reason-for-declination-form-wrapper">
          <form>
            {/* Amount */}
            <div className="form-group">
              <TextInput
                type={"text"}
                id={"wallet-amount-text-input"}
                className={"wallet-amount-input"}
                placeholder={""}
                value={handleFormatNumberInputs(fundAmount)}
                onChange={(e) => {
                  // Remove all the commas from the number value
                  let value = e.target.value;
                  let valueWithoutCommas = value.split(",").join("");
                  setFundAmount(valueWithoutCommas);
                }}
                required={true}
                label={"Enter the amount you want to fund into your wallet"}
              />
            </div>

            <div className="fund-wallet-modal-action-buttons-wrapper">
              {/* Submit Create Group Btn Wrapper */}
              <div className="submit-fund-wallet-btn-wrapper">
                <button onClick={(e) => handleConfirmTriggerPaymentGateway(e)}>
                  Fund Wallet
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default FundWalletModal;
