import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./AuthNavbar.css";
import credleyLogo from "../../images/credley-logo.svg";
import angleDown from "../../images/angle-down.svg";
import merchantSignupIcon from "../../images/merchant-signup-icon.svg";
import employerSignupIcon from "../../images/employer-signup-icon.svg";
import navburgerIcon from "../../images/navburger-icon.svg";
import navcloseIcon from "../../images/navclose-icon.svg";

function AuthNavbar() {
  // States, Functions and Variables
  const location = useLocation();
  const navigate = useNavigate();
  // States

  const handleNavigateHome = function () {
    window.location.href = "https://www.credley.africa";
  };

  // Useeffect
  // Hover Modal Display
  useEffect(() => {
    // Modal Container
    const getStartedModalContainer = document.querySelector(
      ".nav-get-started-modal"
    );

    // get Started Button
    const navGetStartedButtonContainer = document.querySelector(
      ".navbar-action-button-section"
    );

    // mouse over / display modal
    navGetStartedButtonContainer?.addEventListener("mouseover", (e) => {
      e.stopPropagation();

      getStartedModalContainer?.classList.remove("none");
    });

    //Mouse Out, Remove modal
    navGetStartedButtonContainer?.addEventListener("mouseout", (e) => {
      e.stopPropagation();

      getStartedModalContainer?.classList.add("none");
    });
  }, []);

  // Mobile AuthNavbar Dropdown Display
  useEffect(() => {
    const mobileNavBurgerContainer = document.querySelector(
      ".nav-burger-container"
    );
    const mobileNavCloseContainer = document.querySelector(
      ".nav-close-container"
    );

    const mobileNavbarDropownContainer = document.querySelector(
      ".mobile-navbar-modal-container"
    );

    mobileNavBurgerContainer?.addEventListener("click", (e) => {
      e.stopPropagation();

      // Toggle the Navmodal
      mobileNavbarDropownContainer?.classList.remove("none");
    });

    mobileNavCloseContainer?.addEventListener("click", (e) => {
      e.stopPropagation();

      // Toggle the Navmodal
      mobileNavbarDropownContainer?.classList.add("none");
    });
  }, []);

  useEffect(() => {
    const mobileNavbarDropownContainer = document.querySelector(
      ".mobile-navbar-modal-container"
    );
    // Toggle the Navmodal
    mobileNavbarDropownContainer?.classList.add("none");
  }, [location]);

  return (
    <div className="navbar-container">
      <div className="navbar-container--inner">
        {/* Company Logo section / Left Section  */}
        <div className="company-logo-wrapper" onClick={handleNavigateHome}>
          <img src={credleyLogo} width={100} height={30} alt="" />
        </div>

        {/* Nav Link Section / Midle Section*/}
        <div className="top-nav-link-section">
          {/*Shop Link */}
          {/* <div className={`nav-link-wrapper shop-link`}>
            <Link to={"#"}>Shop</Link>
          </div> */}

          {/* Salary Advance Link */}
          <div className={`nav-link-wrapper salary-advance-link`}>
            <Link
              to={"#"}
              onClick={() => {
                window.location.href = `${process.env.REACT_APP_LANDING_URL}/#salary-advance`;
              }}
            >
              Salary Advance
            </Link>
          </div>

          {/* BNPL Link */}
          <div className={`nav-link-wrapper bnpl-link `}>
            <Link
              to={"#"}
              onClick={() => {
                window.location.href = `${process.env.REACT_APP_LANDING_URL}/#bnpl`;
              }}
            >
              BNPL
            </Link>
          </div>

          {/* How It works Link */}
          <div className={`nav-link-wrapper how-it-works-link}`}>
            <Link
              to={"#"}
              onClick={() => {
                window.location.href = `${process.env.REACT_APP_LANDING_URL}/#how-it-works`;
              }}
            >
              How it works
            </Link>
          </div>

          {/* FAQs Link */}
          <div className={`nav-link-wrapper faqs-link`}>
            <Link
              to={"#"}
              onClick={() => {
                window.location.href = `${process.env.REACT_APP_LANDING_URL}/#faqs`;
              }}
            >
              FAQs
            </Link>
          </div>
        </div>

        {/* Action Button Section / Right Section */}
        <div className="navbar-action-button-section">
          <div className="get-started-btn-wrapper">
            <button className="gradientButtton">
              Get started
              <img
                src={angleDown}
                width={21}
                height={21}
                alt=""
                className="bg-none"
              />
            </button>
          </div>

          {/* Get Started Modal */}
          <div className="nav-get-started-modal none">
            <div className="get-started-modal-wrapper">
              {/* Merchantt signup wrapper */}
              <a
                href={`${process.env.REACT_APP_MERCHANT_URL}/merchant/signup`}
                className="merchant-signup-link-wrapper"
              >
                <div className="icon-section">
                  <img
                    src={merchantSignupIcon}
                    width={18}
                    height={18}
                    alt=""
                    className=""
                  />
                </div>

                {/* Wrapper main Content */}
                <div className="wrapper-main-section">
                  <div className="wrapper-title-text">Signup as Merchant</div>
                  <div className="wrapper-sub-text">
                    Register as a Merchant, Upload your products, your payment
                    is guaranteed.
                  </div>
                </div>
              </a>

              {/* Employer signup wrapper */}
              <Link
                to={"/partner/signup"}
                className="employer-signup-link-wrapper"
              >
                <div className="icon-section">
                  <img
                    src={employerSignupIcon}
                    width={18}
                    height={18}
                    alt=""
                    className=""
                  />
                </div>

                {/* Wrapper main Content */}
                <div className="wrapper-main-section">
                  <div className="wrapper-title-text">
                    Signup as an Employer
                  </div>

                  <div className="wrapper-sub-text">
                    Register as a company and make everyday a pay day for your
                    staff.
                  </div>
                </div>
              </Link>

              {/* Download App Buttons Wrapper */}
              <div className="download-btn-section">
                {/* Play Store Download Button */}
                {/* <div className="playstore-download-link">
                  <button>
                    <Image
                      src="/playstore-download-img.png"
                      width={131}
                      height={46}
                      layout="intrinsic"
                      alt=""
                      className=""
                    />
                  </button>
                </div> */}

                {/* AppStore Download Button */}
                {/* <div className="appstore-download-link ml-4">
                  <button>
                    <Image
                      src="/appstore-download-img.png"
                      width={131}
                      height={46}
                      layout="intrinsic"
                      alt=""
                      className=""
                    />
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* MOBILE VIEW FOR THE NAVBAR LEFT */}
      <div className="MOBILE-navbar-container--inner">
        {/* Company Logo section / Left Section  */}
        <div className="company-logo-wrapper">
          <img
            src={credleyLogo}
            width={85}
            height={20}
            alt=""
            className="bg-none"
          />
        </div>

        {/* Navburger Container */}
        <div className="nav-burger-container">
          <div className="image-wrapper">
            <img
              src={navburgerIcon}
              width={37}
              height={37}
              alt=""
              className=""
            />
          </div>
        </div>

        {/* Dropdown AuthNavbar Container */}
        <div className="mobile-navbar-modal-container none">
          {/* Navclose Container */}
          <div className="nav-close-container">
            <div className="image-wrapper">
              <img
                src={navcloseIcon}
                width={37}
                height={37}
                alt=""
                className=""
              />
            </div>
          </div>

          <div className="mobile-nav-links-container">
            {/*Shop Link */}
            {/* <div
              className={`nav-link-wrapper shop-link mobile--nav-link-wrapper`}
            >
              <Link to={"#"}>Shop</Link>
            </div> */}

            {/* Salary Advance Link */}
            <div
              className={`nav-link-wrapper salary-advance-link mobile--nav-link-wrapper`}
            >
              <Link
                to={"#"}
                onClick={() => {
                  window.location.href = `${process.env.REACT_APP_LANDING_URL}/#salary-advance`;
                }}
              >
                Salary Advance
              </Link>
            </div>

            {/* BNPL Link */}
            <div
              className={`nav-link-wrapper bnpl-link mobile--nav-link-wrapper`}
            >
              <Link
                to={"#"}
                onClick={() => {
                  window.location.href = `${process.env.REACT_APP_LANDING_URL}/#bnpl`;
                }}
              >
                BNPL
              </Link>
            </div>

            {/* How It works Link */}
            <div
              className={`nav-link-wrapper how-it-works-link mobile--nav-link-wrapper`}
            >
              <Link
                to={"#"}
                onClick={() => {
                  window.location.href = `${process.env.REACT_APP_LANDING_URL}/#how-it-works`;
                }}
              >
                How it works
              </Link>
            </div>

            {/* FAQs Link */}
            <div
              className={`nav-link-wrapper faqs-link mobile--nav-link-wrapper`}
            >
              <Link
                to={"#"}
                onClick={() => {
                  window.location.href = `${process.env.REACT_APP_LANDING_URL}/#faqs`;
                }}
              >
                FAQs
              </Link>
            </div>

            {/* Lower section */}
            {/* mobile-merchant-employer-signup-wrapper */}
            <div className="mobile-merchant-employer-signup-wrapper">
              {/* Merchantt signup wrapper */}
              <div className="mobile--merchant-signup-link-wrapper">
                <Link
                  to={`${process.env.REACT_APP_MERCHANT_URL}/merchant/signup`}
                  className="employer-signup-link-wrapper"
                >
                  <div className="icon-section">
                    <img
                      src={merchantSignupIcon}
                      width={18}
                      height={18}
                      alt=""
                      className=""
                    />
                  </div>

                  {/* Wrapper main Content */}
                  <div className="wrapper-main-section">
                    <div className="wrapper-title-text">Signup as Merchant</div>

                    <div className="wrapper-sub-text text-grayTertiary">
                      Register as a Merchant, Upload your products, your payment
                      is guaranteed.
                    </div>
                  </div>
                </Link>
              </div>

              {/* Employer signup wrapper */}
              <div className="mobile--employer-signup-link-wrapper">
                <Link
                  to={"/partner/signup"}
                  className="employer-signup-link-wrapper"
                >
                  <div className="icon-section">
                    <img
                      src={employerSignupIcon}
                      width={18}
                      height={18}
                      alt=""
                      className=""
                    />
                  </div>

                  {/* Wrapper main Content */}
                  <div className="wrapper-main-section ml-5 ">
                    <div className="wrapper-title-text text-purplePrimary text-xl font-bold  2md:text-sm">
                      Signup as an Employer
                    </div>

                    <div className="wrapper-sub-text text-grayTertiary mt-3 text-xs font-medium  2md:mt-1">
                      Register as a company and make everyday a pay day for your
                      staff.
                    </div>
                  </div>
                </Link>
              </div>

              {/* Download App Buttons Wrapper */}
              <div className="download-btn-section mt-7 md:mt-2 xs:mt-1 flex justify-center items-center">
                {/* Play Store Download Button */}
                {/* <div className="playstore-download-link">
                  <button>
                    <Image
                      src="/playstore-download-img.png"
                      width={131}
                      height={46}
                      layout="intrinsic"
                      alt=""
                      className=""
                    />
                  </button>
                </div> */}

                {/* AppStore Download Button */}
                {/* <div className="appstore-download-link ml-4">
                  <button>
                    <Image
                      src="/appstore-download-img.png"
                      width={131}
                      height={46}
                      layout="intrinsic"
                      alt=""
                      className=""
                    />
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthNavbar;
