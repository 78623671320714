import React, { useState, useEffect, MouseEvent, useContext } from "react";
import { useNavigate } from "react-router-dom";
import angleLeftIcon from "../../../images/angle-left.svg";
import SuccessHandler from "../../success/SuccessHandler";
import { useAppDispatch } from "../../../hooks";
import { logoutUser } from "../../../redux/actions/authActions";
import useDisplayMessage from "../../../hooks/useDisplayMessage";
import SetupNavigation from "./navigation/SetupNavigation";
import APIConfiguration from "./subpages/api-configuration/APIConfiguration";
import AccountDetailsForm from "./subpages/account-details/AccountDetailsForm";
import { UserContext } from "../../contexts/UserContext";
import { ErrorHandlerType, SetErrorHandlerType } from "../../../Types";

interface IProps {
  pageContent: string;
  pageType: string;
  errorHandlerObj: ErrorHandlerType;
  setErrorHandlerObj: SetErrorHandlerType;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
}

const SetupLayout: React.FC<IProps> = function ({
  pageContent,
  pageType,
  errorHandlerObj,
  setErrorHandlerObj,
  setPageContent,
}) {
  const navigate = useNavigate();
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const { setUser, fetchUser }: any = useContext(UserContext);

  // States
  const [isAPISecretKeyExisting, setIsAPISecretKeyExisting] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isAccountDetailsFormFilled, setIsAccountDetailsFormFilled] =
    useState(false);

  // handle Log Out User
  const handleLogOutUser = function (e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    dispatch(logoutUser(navigate, setUser));
  };

  // Handles Display the error and success message and scrolls to top
  const { successHandlerObj, setSuccessHandlerObj } = useDisplayMessage();

  useEffect(() => {
    // Dummy Call to check internet connection and display error mssage on NOT
    window.scrollTo({ top: 0 });
    fetchUser();
  }, [pageContent]);

  return (
    <div className="dashboard-setup-layout-container">
      <div className="employees--actions-handler-wrapper">
        {/* Error handler component */}
        {/* <ErrorHandler errorHandlerObj={errorHandlerObj} /> */}

        {/* Success handler component */}
        <SuccessHandler successHandlerObj={successHandlerObj} />
      </div>
      {/* Dashboard Setup Top */}
      <div
        className={`dashboard-setup-top-container ${
          successHandlerObj.isSuccess || errorHandlerObj.hasError
            ? "push-wrapper-down"
            : ""
        }`}
      >
        <div className="dashboard-setup-top--left-wrapper">
          {/* Back button Wrapper */}
          <div className="back-button-wrapper settings--back-button-wrapper">
            <button
              onClick={() => {
                if (pageType === "settings") {
                  navigate(-1);
                } else {
                  setPageContent("main");
                }
              }}
            >
              <img src={angleLeftIcon} alt="" />
            </button>
          </div>
          {/* Setup Top Title */}
          <div className="dashboard-setup-top-title">
            {pageType === "settings" ? "Settings" : "Set up"}
          </div>
        </div>
      </div>

      {/* Setup Layout main Container */}
      <div className="dashboard-setup-layout-main-container">
        {/* Dasboard setup Left Section / Dashboard setup navigation container */}
        <SetupNavigation
          pageContent={pageContent}
          pageType={pageType}
          isAccountDetailsFormFilled={isAccountDetailsFormFilled}
          isAPISecretKeyExisting={isAPISecretKeyExisting}
          setPageContent={setPageContent}
          handleLogOutUser={handleLogOutUser}
        />

        {/* Dasboard Setup Right Section / Dashboard setup form container */}
        <div className="dashboard-setup-layout--right-section dashboard-setup-layout-form-container">
          {/* ACCOUNT DETAILS Setup Inner Container */}
          <div
            className={`account-details-setup--inner ${
              pageContent !== "company-info-setup" ? "none" : ""
            }`}
          >
            <AccountDetailsForm
              pageType={pageType}
              setPageContent={setPageContent}
              setIsAccountDetailsFormFilled={setIsAccountDetailsFormFilled}
              setErrorHandlerObj={setErrorHandlerObj}
              setSuccessHandlerObj={setSuccessHandlerObj}
            />
          </div>

          {/* API CONFIGURATION Setup Inner Container */}
          <div
            className={`api-config-setup--inner ${
              pageContent !== "api-config-setup" ? "none" : ""
            }`}
          >
            <APIConfiguration
              pageType={pageType}
              setIsAPISecretKeyExisting={setIsAPISecretKeyExisting}
              setSuccessHandlerObj={setSuccessHandlerObj}
              setErrorHandlerObj={setErrorHandlerObj}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupLayout;
