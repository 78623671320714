import axios from "axios";
import { GET_ALL_TEAM_MEMBER } from "../Constants";
import { api } from "../../api/config";
import {
  SetErrorHandlerType,
  SetIsLoadingType,
  SetSuccessHandlerType,
} from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";
import { NavigateFunction } from "react-router-dom";

// Interfaces
interface IInviteTeamData {
  email: string;
  role: string;
}

interface IOnboardTeamData {
  firstName: string;
  lastName: string;
  email: string;
  partnerId: string;
  password: string;
}

const url = `${api}/api/v1/partners`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get all Team members
export const getAllTeamMembers =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/get-teams`)
      .then((res) => {
        console.log(res.data.data);
        dispatch({ type: GET_ALL_TEAM_MEMBER, payload: res.data.data });
      })
      .catch((err) => {
        handleAPIError(err, dispatch, setErrorHandlerObj);
      })
      .finally(() => setIsLoading(false));
  };

// Invite Team
export const inviteTeam =
  (
    data: IInviteTeamData,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/invite-team`, data)
      .then((res) => {
        navigate("/partner/teams", {
          state: {
            message: "User has been successfully invited to your team!",
          },
        });
      })
      .catch((err) => {
        handleAPIError(err, dispatch, setErrorHandlerObj);
      })
      .finally(() => setIsLoading(false));
  };

// Onboard Team
export const onboardTeam =
  (
    data: IOnboardTeamData,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setRegisterSuccessful: React.Dispatch<React.SetStateAction<boolean>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/onboard-team`, data)
      .then((res) => {
        setRegisterSuccessful(true);
        // setSuccessHandlerObj({
        //   isSuccess: true,
        //   message: `${data.firstName} ${data.lastName} has been successfully onboarded to your team!`,
        // });
      })
      .catch((err) => {
        handleAPIError(err, dispatch, setErrorHandlerObj);
      })
      .finally(() => setIsLoading(false));
  };
