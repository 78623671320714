import React from "react";
import Top from "../../components/app-top/Top";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Main from "../../components/main-container/Main";
import ErrorHandler from "../../components/error/ErrorHandler";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import TransactionsHistoryTable from "./transactions-history-table/TransactionsHistoryTable";

function Transactions() {
  // Functions, States and Variables
  // Hooks
  const { errorHandlerObj, setErrorHandlerObj } = useDisplayMessage();
  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Transactions"} />

      <Main>
        <div className="app-page-container--inner">
          <div className="app--actions-handler-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
          </div>
          {/* app-container-main-section */}
          <section className="app-container-main-section">
            {/* Transactions History Table */}
            <TransactionsHistoryTable setErrorHandlerObj={setErrorHandlerObj} />
          </section>
        </div>
      </Main>
    </div>
  );
}

export default Transactions;
