import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Top from "../../components/app-top/Top";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Main from "../../components/main-container/Main";
import ErrorHandler from "../../components/error/ErrorHandler";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import TeamsTopSection from "./teams-top-section/TeamsTopSection";
import TeamsTableSection from "./teams-table-section/TeamsTableSection";
import SuccessHandler from "../../components/success/SuccessHandler";

function Teams() {
  // Functions, States and Variables
  const location = useLocation();
  // Hooks
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  useEffect(() => {
    if (location.state) {
      const { message }: any = location.state;
      if (message) setSuccessHandlerObj({ isSuccess: true, message });

      if (message) {
        // Cleanup function that clears location state
        return () => {
          window.history.replaceState({}, document.title);
        };
      }
    }
  }, [location]);

  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Teams"} />

      <Main>
        <div className="app-page-container--inner">
          <div className="app--actions-handler-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
            {/* Success handler component */}
            <SuccessHandler successHandlerObj={successHandlerObj} />
          </div>

          {/* app-container-main-section */}
          <section className="app-container-main-section">
            {/* Teams Top Section */}
            <TeamsTopSection />

            {/* Teams Table Section */}
            <TeamsTableSection setErrorHandlerObj={setErrorHandlerObj} />
          </section>
        </div>
      </Main>
    </div>
  );
}

export default Teams;
