import React, { useContext, useState, useEffect } from "react";
import "./TeamsTableSection.css";
import { ITeamMember, SetErrorHandlerType } from "../../../Types";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { SearchContext } from "../../../components/contexts/SearchContext";
import isEmpty from "../../../validation/isEmpty";
import { getAllTeamMembers } from "../../../redux/actions/teamActions";
import PrimaryButton from "../../../components/buttons/primary-button/PrimaryButton";
import { useNavigate } from "react-router-dom";
import FEPagination from "../../../components/pagination/fe-pagination/FEPagination";
import TeamsTable from "./teams-table/TeamsTable";
import SecondaryButton from "../../../components/buttons/secondary-button/SecondaryButton";

// Interfaces
interface IProps {
  setErrorHandlerObj: SetErrorHandlerType;
}

function TeamsTableSection({ setErrorHandlerObj }: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const teamMembers: ITeamMember[] | [] = useAppSelector(
    (state) => state.teams.team
  );
  // States
  const { searchedValue }: any = useContext(SearchContext);
  const [pageContent, setPageContent] = useState("main");
  const [allTeamMembers, setAllTeamMembers] = useState<ITeamMember[] | null>(
    null
  );
  const [selectedMemberForPreview, setSelectedMemberForPreview] =
    useState<ITeamMember | null>(null);
  const [currentTeamMembersPosts, setCurrentTeamMembersPosts] = useState<
    ITeamMember[] | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //   handle Filter Table
  const handleFilterTable = function () {
    if (isEmpty(teamMembers)) return false;
    const allTeamMembersData = teamMembers?.filter(
      (member) =>
        member?.firstName
          ?.toLowerCase()
          .includes(searchedValue.toLowerCase()) ||
        member?.lastName?.toLowerCase().includes(searchedValue.toLowerCase())
    );

    setAllTeamMembers(allTeamMembersData);
    // Update table state to merchant rows
    if (!allTeamMembers || !allTeamMembersData) return false;
    const currentRows = allTeamMembersData.slice(
      indexOfFirstPost,
      indexOfLastPost
    );
    setCurrentTeamMembersPosts(currentRows);
  };

  // UseEffects
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pageContent]);

  useEffect(() => {
    dispatch(getAllTeamMembers(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    handleFilterTable();
  }, [teamMembers, searchedValue]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchedValue]);

  return (
    <section className={`employers-table-section`}>
      {/* Employers Table Top Wrapper */}
      <div className="teams-table--top-wrapper">
        <div className="teams_table_action_btn_wrapper">
          {/* Invite Team Member */}
          <PrimaryButton
            placeholder="Invite Team Member"
            onClick={() => navigate("/partner/teams/invite-team")}
          />

          {/* Onboard Team Member */}
          {/* <SecondaryButton
            placeholder="Onboard Team Member"
            onClick={() => navigate("/partner/teams/onboard-team")}
          /> */}
        </div>
      </div>

      {/* Employers Table Container */}
      <section className={`employers-table-wrapper`}>
        <TeamsTable
          isLoading={isLoading}
          currentTeamMembersPosts={currentTeamMembersPosts}
          setPageContent={setPageContent}
          setSelectedMemberForPreview={setSelectedMemberForPreview}
        />

        {/* Pagination */}
        {allTeamMembers && !isEmpty(currentTeamMembersPosts) && (
          <FEPagination
            paginate={paginate}
            rowsPerPage={rowsPerPage}
            totalPosts={allTeamMembers.length}
            currentPage={currentPage}
          />
        )}
      </section>
    </section>
  );
}

export default TeamsTableSection;
