import React from "react";
import useDisplayMessage from "../../../hooks/useDisplayMessage";
import Top from "../../../components/app-top/Top";
import Sidebar from "../../../components/app-sidebar/Sidebar";
import Main from "../../../components/main-container/Main";
import ErrorHandler from "../../../components/error/ErrorHandler";
import PrimaryBackButton from "../../../components/primary-back-button/PrimaryBackButton";
import { useNavigate } from "react-router-dom";
import FormSection from "./form-section/FormSection";

function InviteTeam() {
  // Functions, States and Variables
  const navigate = useNavigate();
  // Hooks
  const { errorHandlerObj, setErrorHandlerObj } = useDisplayMessage();
  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Teams"} />

      <Main>
        <div className="app-page-container--inner">
          <div className="app--actions-handler-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
          </div>

          {/* app-container-main-section */}
          <section className="app-container-main-section">
            {/*  Top Section */}
            <div className="team--invite-team--top-wrapper">
              <div className="primary-back-button-container">
                {/* Back button Wrapper */}
                <PrimaryBackButton
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                {/* Top Title */}
                <div className="primary-back-button-title">Invite Team</div>
              </div>
            </div>

            {/* Teams Form Section */}
            <FormSection setErrorHandlerObj={setErrorHandlerObj} />
          </section>
        </div>
      </Main>
    </div>
  );
}

export default InviteTeam;
