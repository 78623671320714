import React from "react";
import Top from "../../components/app-top/Top";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Main from "../../components/main-container/Main";
import ErrorHandler from "../../components/error/ErrorHandler";
import useDisplayMessage from "../../hooks/useDisplayMessage";

function Documentation() {
  // Functions, States and Variables
  // Hooks
  const { errorHandlerObj } = useDisplayMessage();
  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Documentation"} />

      <Main>
        <div className="app-page-container--inner">
          <div className="app--actions-handler-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
          </div>

          {/* app-container-main-section */}
          <section className="app-container-main-section">
            Documentation
          </section>
        </div>
      </Main>
    </div>
  );
}

export default Documentation;
