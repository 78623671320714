import dashboardIcon from "../images/sidebar-svg/dashboard-icon.svg";
import dashboardIconActive from "../images/sidebar-svg/dashboard-icon-active.svg";
import teamsIcon from "../images/sidebar-svg/teams-icon.svg";
import teamsIconActive from "../images/sidebar-svg/teams-icon-active.svg";
import transactionsIcon from "../images/sidebar-svg/transactions-icon.svg";
import transactionsIconActive from "../images/sidebar-svg/transactions-icon-active.svg";
import settlementsIcon from "../images/sidebar-svg/settlements-icon.svg";
import settlementsIconActive from "../images/sidebar-svg/settlements-icon-active.svg";
import apiDetailsIcon from "../images/sidebar-svg/apiDetails-icon.svg";
import apiDetailsIconActive from "../images/sidebar-svg/apiDetails-icon-active.svg";

export interface IAllNavLinks {
  name: string;
  active: boolean;
  icon: string;
  activeIcon: string;
  link: string;
  openNewTab?: boolean;
}

export const allNavLinks = [
  {
    name: "Dashboard",
    active: false,
    icon: dashboardIcon,
    activeIcon: dashboardIconActive,
    link: "/partner/dashboard",
  },
  // {
  //   name: "Documentation",
  //   active: false,
  //   icon: settlementsIcon,
  //   activeIcon: settlementsIconActive,
  //   link: "https://credley.readme.io/reference/",
  //   openNewTab: true,
  // },
  {
    name: "Transactions",
    active: false,
    icon: transactionsIcon,
    activeIcon: transactionsIconActive,
    link: "/partner/transactions",
  },
  {
    name: "API Details",
    active: false,
    icon: apiDetailsIcon,
    activeIcon: apiDetailsIconActive,
    link: "/partner/api-details",
  },
  {
    name: "Teams",
    active: false,
    icon: teamsIcon,
    activeIcon: teamsIconActive,
    link: "/partner/teams",
  },
];
