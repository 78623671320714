import React, {
  ChangeEvent,
  MouseEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../../../Types";
import axios from "axios";
import { api } from "../../../../../api/config";
import TextInput from "../../../../input-components/text-input/TextInput";
import LoadingSpinner from "../../../../loading-spinner/LoadingSpinner";
import { useAppDispatch } from "../../../../../hooks";
import { UserContext } from "../../../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import Textarea from "../../../../input-components/textarea/Textarea";
import FileUploadInput from "../../../../input-components/file-upload/FileUploadInput";
import { onboardPartner } from "../../../../../redux/actions/userActions";

// Interfaces
interface IProps {
  pageType: string;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setIsAccountDetailsFormFilled: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
}

interface IAccountDetailsFormData {
  cacDocumentImage: string;
  utilityImage: string;
  address: string;
  natureOfBusiness: string;
}

function AccountDetailsForm({
  pageType,
  setPageContent,
  setIsAccountDetailsFormFilled,
  setErrorHandlerObj,
  setSuccessHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user: userInfo }: any = useContext(UserContext);
  // States
  const [formInputStates, setFormInputStates] =
    useState<IAccountDetailsFormData>({
      cacDocumentImage: "",
      utilityImage: "",
      address: "",
      natureOfBusiness: "",
    });
  const [isLoading, setIsLoading] = useState(false);
  const [isFileUploadLoading, setIsFileUploadLoading] = useState(false);
  const [activeFileinput, setActiveFileInput] = useState("");
  // Functions
  // Handle Update profile photo
  const handleUpdateFile = async function (
    e: ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) {
    setActiveFileInput(fieldName);
    // Create or update the  profile photo
    if (!e.target.files) return false;
    setErrorHandlerObj({ hasError: false, message: "" });
    if (e.target.files[0].size <= 512000) {
      // handle Upload Product Img
      const token: any = localStorage.getItem("jwtToken");
      axios.defaults.headers.common["authorization"] = token;

      try {
        const selectedFile = e.target.files[0];
        const formData = new FormData();
        formData.append("file", selectedFile);

        setIsFileUploadLoading(true);
        const { data } = await axios.post(`${api}/api/v1/upload`, formData);

        setIsFileUploadLoading(false);
        handleUpdateFormInputState(fieldName, data);
      } catch (ex) {
        setIsFileUploadLoading(false);

        setErrorHandlerObj({
          hasError: true,
          message: "Upload failed!",
        });
      }
    } else {
      setErrorHandlerObj({
        hasError: true,
        message: "File size exceeds 500kb!",
      });
    }
  };

  const handleUpdateFormInputState = function (
    fieldName: string,
    fieldValue: string
  ) {
    const updatedFormInputStates = {
      ...formInputStates,
      [fieldName]: fieldValue,
    };
    setFormInputStates(updatedFormInputStates);
  };

  // Handle Submit Company Payday And Size
  const handleSubmitAccountDetails = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    const isFormFieldEmpty = Object.keys(formInputStates).some(
      (inputKey) =>
        formInputStates[inputKey as keyof typeof formInputStates] === ""
    );
    //   Set the form page to filled
    if (!isFormFieldEmpty) {
      e.preventDefault();
      const data = formInputStates;

      //  Call the API to onboard partner
      dispatch(
        onboardPartner(
          data,
          pageType,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          navigate
        )
      );
    }
  };

  // UseEffects
  useEffect(() => {
    if (userInfo) {
      let newStateObj = {
        // Prefilled from user ddata
        cacDocumentImage: userInfo?.partnerId?.cacDocumentImage || "",
        utilityImage: userInfo?.partnerId?.utilityImage || "",
        address: userInfo?.partnerId?.address || "",
        natureOfBusiness: userInfo?.partnerId?.natureOfBusiness || "",
      };
      setFormInputStates(newStateObj);

      const isFormFieldEmpty = Object.keys(newStateObj).some(
        (inputKey) => newStateObj[inputKey as keyof typeof newStateObj] === ""
      );
      //   Set the form page to filled
      if (!isFormFieldEmpty) setIsAccountDetailsFormFilled(true);
    }
  }, [userInfo]);

  return (
    <form>
      {/* Partner Name form group */}
      <div className="form-group">
        <TextInput
          type={"text"}
          id={"partner-name-text-input"}
          className={"partner-name-input"}
          placeholder={""}
          value={`${userInfo?.firstName} ${userInfo?.lastName}` || ""}
          onChange={(e) => {}}
          label={"Partner Name"}
          disabled={true}
        />
      </div>

      {/* Nature of Business form group */}
      <div className="form-group">
        <TextInput
          type={"text"}
          id={"partner-busineess-nature-text-input"}
          className={"partner-busineess-nature-input"}
          placeholder={""}
          value={formInputStates.natureOfBusiness}
          onChange={(e) =>
            handleUpdateFormInputState("natureOfBusiness", e.target.value)
          }
          required={true}
          label={"Nature of Business"}
        />
      </div>

      {/*  Address form group */}
      <div className="form-group">
        <Textarea
          id={"partner-address-text-input"}
          className={"partner-address-input"}
          placeholder={""}
          value={formInputStates.address}
          onChange={(e) =>
            handleUpdateFormInputState("address", e.target.value)
          }
          required={true}
          label={"Address"}
        />
      </div>

      {/* CAC document form group */}
      <div className="form-group">
        <FileUploadInput
          label="CAC Document Upload"
          value={formInputStates.cacDocumentImage}
          isLoading={
            activeFileinput === "cacDocumentImage" && isFileUploadLoading
          }
          name="cac_document"
          placeholder={"CAC Document"}
          required={true}
          onChange={(e) => handleUpdateFile(e, "cacDocumentImage")}
        />
      </div>

      {/* Utility Bill form group */}
      <div className="form-group">
        <FileUploadInput
          label="Utility Bill Upload"
          value={formInputStates.utilityImage}
          isLoading={activeFileinput === "utilityImage" && isFileUploadLoading}
          name="utility_bill"
          placeholder={"Utility Bill"}
          required={true}
          onChange={(e) => handleUpdateFile(e, "utilityImage")}
        />
      </div>

      {/* Submit form button */}
      <div className="submit-setup-form-button-wrapper">
        {isLoading && <LoadingSpinner />}
        <button onClick={handleSubmitAccountDetails}>Submit</button>
      </div>
    </form>
  );
}

export default AccountDetailsForm;
