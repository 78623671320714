import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AccountSetup from "../../components/account-setup/AccountSetup";
import { useAppDispatch } from "../../hooks";
import { logoutUser } from "../../redux/actions/authActions";
import useInactivityLogout from "../../hooks/useInactivityLogout";
import { UserContext } from "../../components/contexts/UserContext";

function AccountSettings() {
  // Funtion, State and Variables
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setUser }: any = useContext(UserContext);
  // Functions
  // Handles logout of user
  const handleLogOutUser = function () {
    dispatch(logoutUser(navigate, setUser));
  };

  useInactivityLogout({
    inactivityExpireTime: Number(process.env.REACT_APP_INACTIVITY_EXPIRE_TIME),
    onExpired: handleLogOutUser,
  });

  return <AccountSetup pageType={"settings"} />;
}

export default AccountSettings;
