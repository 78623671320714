import React, { MouseEvent, useEffect, useState } from "react";
import "./APIConfiguration.css";
import { SetErrorHandlerType, SetSuccessHandlerType } from "../../../Types";
import eyeOpenIcon from "../../../images/eye-open-icon.svg";
import eyeCloseIcon from "../../../images/eye-close-icon.svg";
import copyIcon from "../../../images/copy-icon-secondary.svg";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getAPIDetails } from "../../../redux/actions/apiDetailsActions";
import PrimaryButton from "../../../components/buttons/primary-button/PrimaryButton";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import TextInput from "../../../components/input-components/text-input/TextInput";

// Interfaces
interface IProps {
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
  setIsConfirmationModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

function APIConfiguration({
  setIsConfirmationModalOpened,
  setSuccessHandlerObj,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const apiDetails = useAppSelector((state) => state.apiDetails.apiDetails);
  const dispatch = useAppDispatch();
  //   States
  const [apiKeyFormInputStates, setApiKeyFormInputStates] = useState({
    secretKey: "",
    publicKey: "",
  });

  const [isGenerateLoading, setIsGenerateLoading] = useState(false);

  const [isSecretKeyVisible, setIsSecretKeyVisible] = useState(false);
  const [isAPIKeyExisting, setIsAPIKeyExisting] = useState(false);

  //   Functions
  // handle Copy Secret Key To Clipboard
  const handleCopySecretKeyToClipboard = function (
    e: MouseEvent<HTMLImageElement>
  ) {
    e.preventDefault();
    if (apiKeyFormInputStates.publicKey) {
      navigator.clipboard.writeText(apiKeyFormInputStates.publicKey);
      setSuccessHandlerObj({
        isSuccess: true,
        message: "Public key copied to clipboard!",
      });
    }
  };

  //   Useeffects
  useEffect(() => {
    dispatch(getAPIDetails(setIsGenerateLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    if (apiDetails.apiKey && apiDetails.apiSecret) {
      const apiDetailsData = {
        secretKey: apiDetails.apiSecret,
        publicKey: apiDetails.apiKey,
      };
      setApiKeyFormInputStates(apiDetailsData);
      //   Set secret key is existing state (for header tick)
      setIsAPIKeyExisting(true);
    }
  }, [apiDetails]);

  return (
    <section className="api_configuration_section">
      <div className="api_configuration_title">API Documentation</div>
      <p style={{marginBottom: 50}}>Click <a href="https://credley.readme.io/reference/" target="_blank">here</a> to view our documentation</p>
      
      
      <div className="api_configuration_title">API keys</div>

      <form>
        {isGenerateLoading && <LoadingSpinner />}

        {!isGenerateLoading && !isAPIKeyExisting && (
          <div className="empty_key_wrapper">
            No API key has been generated!
          </div>
        )}

        {!isGenerateLoading && isAPIKeyExisting && (
          <div className="form_group_wrapper">
            {/* API Secret Key form group */}
            <div className="api_secret_key_form_group form-group">
              <img
                src={isSecretKeyVisible ? eyeOpenIcon : eyeCloseIcon}
                alt=""
                className="password-icon-wrapper"
                onClick={() => setIsSecretKeyVisible(!isSecretKeyVisible)}
              />

              <label htmlFor={"api-secret-key-input"}>API Secret Key</label>
              <input
                type={isSecretKeyVisible ? "text" : "password"}
                id={"api-secret-key-input"}
                className={"sapi-secret-key-text-input"}
                value={apiKeyFormInputStates.secretKey}
                onChange={() => {}}
                disabled
              />
            </div>

            <div className="api_secret_key_form_group form-group">
              <TextInput
                type={"text"}
                id={"wallet-amount-text-input"}
                className={"wallet-amount-input"}
                placeholder={""}
                value={apiKeyFormInputStates.publicKey}
                onChange={() => {}}
                required={true}
                label={"API Public Key"}
                disabled
              />

              <div className="secret-key-copy-btn-wrapper">
                <button>
                  <img
                    src={copyIcon}
                    alt=""
                    onClick={(e) => handleCopySecretKeyToClipboard(e)}
                  />
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="primary-button-container generate-secret-key-btn-container">
          <PrimaryButton
            className="generate-secret-key-btn-wrapper"
            placeholder="Generate new secret key"
            onClick={(e) => {
              e.preventDefault();
              setIsConfirmationModalOpened(true);
            }}
          />
          {/* {isLoading && <LoadingSpinner />} */}
        </div>
      </form>
    </section>
  );
}

export default APIConfiguration;
