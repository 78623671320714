import { combineReducers } from "redux";
import apiDetailsReducer from "./apiDetailsReducer";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import transactionsReducer from "./transactionsReducer";
import teamReducer from "./teamReducer";

export default combineReducers({
  user: authReducer,
  errors: errorReducer,
  apiDetails: apiDetailsReducer,
  transactions: transactionsReducer,
  teams: teamReducer,
});
