import React, { useState } from "react";
import Top from "../../components/app-top/Top";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Main from "../../components/main-container/Main";
import ErrorHandler from "../../components/error/ErrorHandler";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import APIConfiguration from "./api-configuration/APIConfiguration";
import SuccessHandler from "../../components/success/SuccessHandler";
import ConfirmActionModal from "../../components/confirm-action-modal/ConfirmActionModal";
import { generateAPIDetails } from "../../redux/actions/apiDetailsActions";
import { useAppDispatch } from "../../hooks";

function APIDetails() {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  // Hooks
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  // Functions, States and Variables
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationModalOpened, setIsConfirmationModalOpened] =
    useState(false);

  // Functions
  const handleGenerateNewSecretKey = function () {
    setErrorHandlerObj({ hasError: true, message: "" });
    setSuccessHandlerObj({ isSuccess: true, message: "" });
    //  Call the API to generate new secret key
    dispatch(
      generateAPIDetails(
        setIsLoading,
        setErrorHandlerObj,
        setSuccessHandlerObj,
        setIsConfirmationModalOpened
      )
    );
  };

  return (
    <div
      className={`app-page-container ${
        isConfirmationModalOpened ? "overflow-hidden" : ""
      }`}
    >
      <Top />
      <Sidebar activeName={"API Details"} />

      {/* Modals */}
      <div
        onClick={() => {
          setIsConfirmationModalOpened(false);
        }}
        className={`gray-overlay ${isConfirmationModalOpened ? "" : "none"}`}
      ></div>

      <ConfirmActionModal
        isLoading={isLoading}
        isModalOpened={isConfirmationModalOpened}
        setIsModalOpened={setIsConfirmationModalOpened}
        confirmationText="This will override your current API keys. Are you sure you want to proceed?"
        handleConfirmAction={handleGenerateNewSecretKey}
      />

      <Main>
        <div className="app-page-container--inner">
          <div className="app--actions-handler-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
            {/* Success handler component */}
            <SuccessHandler successHandlerObj={successHandlerObj} />
          </div>

          {/* app-container-main-section */}
          <section className="app-container-main-section">
            <APIConfiguration
              setErrorHandlerObj={setErrorHandlerObj}
              setSuccessHandlerObj={setSuccessHandlerObj}
              setIsConfirmationModalOpened={setIsConfirmationModalOpened}
            />
          </section>
        </div>
      </Main>
    </div>
  );
}

export default APIDetails;
