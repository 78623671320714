import React from "react";
import "./App.css";
import "./styles/Table.css";
import "./styles/PageLayout.css";
import "./styles/SecondaryModal.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Signin from "./pages/signin/Signin";
import store from "./store";
import { Provider } from "react-redux";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import ResetPassword from "./pages/reset-password/ResetPassword";
import SearchContextProvider from "./components/contexts/SearchContext";
import UserContextProvider from "./components/contexts/UserContext";
import Signup from "./pages/signup/Signup";
import AccountAwaitingApproval from "./components/account-awaiting-approval/AccountAwaitingApproval";
import AwaitingVerification from "./components/account-verification/awaiting-verification/AwaitingVerification";
import AccountVerification from "./components/account-verification/AccountVerification";
import Dashboard from "./pages/partner-dashboard/Dashboard";
import AccountSettings from "./pages/settings/AccountSettings";
import Documentation from "./pages/documentation/Documentation";
import Transactions from "./pages/transactions/Transactions";
import APIDetails from "./pages/api-details/APIDetails";
import Teams from "./pages/teams/Teams";
import DashboardSetup from "./components/dashboard-comps/dashboard-setup/DashboardSetup";
import DashboardAwaiting from "./components/dashboard-comps/dashboard-awaiting/DashboardAwaiting";
import InviteTeam from "./pages/teams/invite-team/InviteTeam";
import OnboardTeam from "./pages/teams/onboard-team/OnboardTeam";

function App() {
  return (
    <div className="app">
      <SearchContextProvider>
        <UserContextProvider>
          <Provider store={store}>
            <Router>
              <Routes>
                <Route path="/" element={<Signin />} />
                <Route path="/signin" element={<Signin />} />
                <Route path="/partner/signup" element={<Signup />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                {/* Account Awaiting Approval */}
                <Route
                  path="/partner/awaiting-approval"
                  element={<AccountAwaitingApproval />}
                />
                {/* Account Awaiting Verification */}
                <Route
                  path="/partner/verification"
                  element={<AwaitingVerification />}
                />
                {/* Verification Page */}
                <Route
                  path="/partner/verification/:token/:id"
                  element={<AccountVerification />}
                />
                {/* Dashboard Setup */}
                <Route
                  path="partner/dashboard/setup"
                  element={<DashboardSetup />}
                />
                <Route
                  path="partner/dashboard/awaiting-approval"
                  element={<DashboardAwaiting />}
                />
                <Route
                  path="partner/dashboard/setup"
                  element={<DashboardSetup />}
                />
                {/* Settings */}
                <Route path="settings" element={<AccountSettings />} />
                {/* Dashboard Page */}
                <Route path="/partner/dashboard" element={<Dashboard />} />
                {/* Documentation Page */}
                <Route
                  path="/partner/documentation"
                  element={<Documentation />}
                />
                {/* Transactions Page */}
                <Route
                  path="/partner/transactions"
                  element={<Transactions />}
                />
                {/* API Details Page */}
                <Route path="/partner/api-details" element={<APIDetails />} />
                {/* Teams Page */}
                <Route path="/partner/teams" element={<Teams />} />
                <Route
                  path="/partner/teams/invite-team"
                  element={<InviteTeam />}
                />
                <Route
                  path="/partner/onboard/:partnerId"
                  element={<OnboardTeam />}
                />
              </Routes>
            </Router>
          </Provider>
        </UserContextProvider>
      </SearchContextProvider>
    </div>
  );
}

export default App;
