import { useContext, useEffect } from "react";
import useInactivityLogout from "../../hooks/useInactivityLogout";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { useAppDispatch } from "../../hooks";
import { logoutUser } from "../../redux/actions/authActions";
import ErrorHandler from "../error/ErrorHandler";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import { handleAPIError } from "../../utils/handleAPIError";

function Main({ children }: any) {
  // Functions, States and Variables
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { setUser, fetchError }: any = useContext(UserContext);
  const { errorHandlerObj, setErrorHandlerObj } = useDisplayMessage();

  // Functions
  // handle Log Out User
  const handleLogOutUser = function () {
    dispatch(logoutUser(navigate, setUser));
  };

  useInactivityLogout({
    inactivityExpireTime: Number(process.env.REACT_APP_INACTIVITY_EXPIRE_TIME),
    onExpired: handleLogOutUser,
  });

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    // Check if User exists || is logged in
    if (!token) {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    if (fetchError && location.pathname.includes("/partners"))
      handleAPIError(fetchError, dispatch, setErrorHandlerObj);
  }, [fetchError]);

  return (
    <div className="company--main-section-wrapper">
      {fetchError && (
        <div className="main--actions-handler-wrapper">
          {/* Error handler component */}
          <ErrorHandler errorHandlerObj={errorHandlerObj} />
        </div>
      )}
      {children}
    </div>
  );
}

export default Main;
